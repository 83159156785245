import React from "react";
import {
  IconButton,
  Typography,
  Divider,
  Box,
  Container,
  ListItem,
  List,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TopBar from "../components/Navbar";

function TermsOfUse({ navigation }: any) {
  return (
    <Box
      style={{
        width: "100%",
        backgroundColor: "#ffffff",
        paddingBottom: "24px",
      }}
    >
      <TopBar navigation={navigation} />
      <Box
        style={{
          position: "relative",
          padding: "24px",
          borderRadius: "8px",
          maxWidth: "100%",
        }}
      >
        <Container
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
          }}
        >
          <Box padding={2}>
            <Typography variant="h6" fontWeight="bold">
              Reshape Terms of Use
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              Welcome to Reshape! Please carefully review these comprehensive
              terms of use ("Terms") before using our AI-powered fitness app.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              By accessing or using Reshape (the "App"), you agree to comply
              with and be bound by these Terms. If you do not agree with any
              part of these Terms, please refrain from using our App.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              Reshape Technologies Inc. is the proprietor and operator of the
              App ("we," "us," or "our"). This Agreement delineates the terms of
              usage for the App and Reshape Technologies Inc.Your access to and
              utilization of this App, along with the services provided through
              it (collectively, the "Services"), are subject to the terms,
              conditions, and notices outlined below (the "Terms of Use"). By
              utilizing the Services, you acknowledge and agree to the entire
              Terms of Service, which we may periodically update. For any
              modifications made to the Terms of Service, kindly refer to this
              page regularly
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              We shall not be held responsible if this App becomes temporarily
              or permanently inaccessible for any reason. We retain the right to
              restrict access to specific sections or the entirety of this App
              at our discretion. This App may contain links to other
              Websites/Apps not under the management of Reshape (the "Linked
              Sites"). Reshape does not control the Linked Sites and disclaims
              any liability for any loss or damage arising from your use of
              them. Your interaction with the Linked Sites is subject to the
              respective terms of service and usage stipulated on each site
            </Typography>

            {/* Add each section with a title and content similarly */}
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              1. Acceptance of Terms
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              By accessing or using ReshapeApp.ai, you acknowledge and agree to
              these Terms, as well as our Privacy Policy, which is incorporated
              herein by reference.
            </Typography>

            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              2. Use of the App
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Eligibility: You must be at least 18 years old to use
              ReshapeApp.ai. By using the App, you represent and warrant that
              you are at least 18 years old and have the legal capacity to enter
              into these Terms.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. User Account: To access certain features of the App, you may
              need to create a user account. You are responsible for maintaining
              the confidentiality of your account credentials.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              c. Accuracy of Information: You agree to provide accurate,
              current, and complete information when using the App and to
              promptly update such information to keep it accurate, current, and
              complete.
            </Typography>
            {/* Add more sections as needed */}
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              3. License and Restrictions
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. License: Subject to these Terms, we grant you a limited,
              non-exclusive, non-transferable, and revocable license to use the
              App for your personal, non-commercial use.
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: "16px", margin: "8px 0" }}
            >
              b. Restrictions: You agree not to:
            </Typography>
            <List component="div" disablePadding style={{ paddingLeft: 16 }}>
              <ListItem>
                <ListItemText primary="i. Use the App for any unlawful purpose or in violation of any applicable laws." />
              </ListItem>
              <ListItem>
                <ListItemText primary="ii. Modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on the App." />
              </ListItem>
              <ListItem>
                <ListItemText primary="iii. Interfere with the operation of the App or any user's enjoyment of it." />
              </ListItem>
            </List>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              4. Intellectual Property
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Ownership: ReshapeApp.ai and its content are owned by us and
              are protected by intellectual property laws. All rights not
              expressly granted herein are reserved.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. User Content: By using the App, you grant us a worldwide,
              non-exclusive, royalty-free, sublicensable, and transferable
              license to use, reproduce, distribute, prepare derivative works
              of, display, and perform your user-generated content in connection
              with the App.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              5. Privacy
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              Our Privacy Policy outlines how we collect, use, and disclose your
              personal information. By using ReshapeApp.ai, you consent to the
              practices described in the Privacy Policy.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              6. Termination
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              We reserve the right to terminate or suspend your access to the
              App, with or without cause, and without notice, at our discretion.
              Upon termination, your license to use the App will cease.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              7. Disclaimer of Warranties
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. General Disclaimer: ReshapeApp.ai is provided "as is" and "as
              available" without warranties of any kind, whether express or
              implied
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. No Medical Advice: The content provided by the App is for
              informational purposes only and is not intended as a substitute
              for professional medical advice, diagnosis, or treatment. Consult
              with a qualified healthcare professional before starting any
              fitness program.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              8. Limitation of Liability
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              To the fullest extent permitted by applicable law, we shall not be
              liable for any direct, indirect, incidental, special,
              consequential, or punitive damages, including but not limited to,
              damages for lost profits, goodwill, use, data, or other intangible
              losses.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              9. Governing Law and Dispute Resolution
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Governing Law: These Terms are governed by and construed in
              accordance with the laws of Ontario, without regard to its
              conflict of law principles.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. Dispute Resolution: Any dispute arising out of or relating to
              these Terms will be resolved by final and binding arbitration
              before a single arbitrator in accordance with the Arbitration Act
              (Ontario), and the arbitration shall take place in Ajax, Ontario.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              10. Changes to Terms
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              We reserve the right to update or modify these Terms at any time.
              The date of the latest revision will be indicated at the top of
              these Terms. Your continued use of the App after any changes
              constitutes acceptance of the new Terms.'
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              11. User Responsibilities
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Health and Fitness Decisions: Users are solely responsible for
              their health and fitness decisions. The App is not a substitute
              for professional medical advice, diagnosis, or treatment.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. Legal Compliance: Users must comply with all applicable laws
              and regulations when using the App
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              12. User Conduct
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Prohibited Conduct: Users agree not to engage in any conduct
              that is harmful, illegal, or interferes with the functioning of
              the App.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. Respect for Others: Users must respect the privacy and rights
              of other users. Any harassment, bullying, or unauthorized sharing
              of personal information is strictly prohibited.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              13. Community Guidelines
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Positive Environment: Users participating in community features
              must adhere to guidelines promoting a positive and supportive
              environment.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              14. Subscription and Payment Terms
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Pricing and Billing: If users choose to subscribe to premium
              features, they agree to the pricing and billing terms specified in
              the App. Payment information must be accurate and up-to-date.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              b. Auto-Renewal: Subscriptions may renew automatically, and users
              are responsible for canceling subscriptions before renewal to
              avoid charges.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              15. Refund and Cancellation Policy
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                margin: "8px 0",
                paddingLeft: "15px",
                fontWeight: "bold",
              }}
            >
              1. In-App Purchases
            </Typography>
            <Typography
              style={{ fontSize: "16px", margin: "8px 0", paddingLeft: 16 }}
            >
              All purchases made through the Reshape app on the App Store and
              Google Play Store are final and non-refundable, except where
              required by applicable law. Users are encouraged to utilize the
              free trial period (if available) before committing to a
              subscription or one-time purchase.
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                margin: "8px 0",
                paddingLeft: "15px",
                fontWeight: "bold",
              }}
            >
              2. Purchases Made Through Other Means
            </Typography>
            <Typography
              style={{ fontSize: "14px", margin: "8px 0", paddingLeft: 30 }}
            >
              If a purchase was made through other means (e.g., directly on our
              website or through a corporate program), the following conditions
              apply:
            </Typography>
            <List component="div" disablePadding style={{ paddingLeft: 16 }}>
              <ListItem>
                <ListItemText primary="• Refunds may be requested within 7 days of purchase, provided the service has not been significantly used or delivered." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• Refund requests must be made by contacting our customer support team at support@reshapeapp.ai. Refund eligibility will be determined on a case-by-case basis." />
              </ListItem>
              <ListItem>
                <ListItemText primary="• Refunds will be processed back to the original payment method used during the purchase." />
              </ListItem>
            </List>
            <Typography
              style={{
                fontSize: "16px",
                margin: "8px 0",
                paddingLeft: "15px",
                fontWeight: "bold",
              }}
            >
              3. Cancellation of Subscriptions
            </Typography>
            <List component="div" disablePadding style={{ paddingLeft: 16 }}>
              <ListItem>
                <ListItemText primary="• For in-app subscriptions: Users can manage or cancel their subscriptions at any time through their respective app store account settings (e.g., App Store or Google Play Store).Upon cancellation, access to premium features will remain active until the end of the current billing period. No refunds will be provided for unused portions of a subscription term." />
              </ListItem>

              <ListItem>
                <ListItemText primary="• For subscriptions purchased through other means: Subscriptions can be canceled by contacting customer support.Access will remain active until the end of the current billing cycle, and no refunds will be issued for the remaining duration" />
              </ListItem>
            </List>
            <Typography
              style={{
                fontSize: "16px",
                margin: "8px 0",
                paddingLeft: "15px",
                fontWeight: "bold",
              }}
            >
              4. Request for Refunds
            </Typography>
            <Typography
              style={{ fontSize: "14px", margin: "8px 0", paddingLeft: 30 }}
            >
              If you believe a refund is warranted due to extraordinary
              circumstances, you may:
            </Typography>
            <List component="div" disablePadding style={{ paddingLeft: 16 }}>
              <ListItem>
                <ListItemText primary="• For app store purchases: Contact the app store directly, as all transactions are processed and governed by their respective refund policies.Reshape does not have the ability to process refunds directly for app store purchases." />
              </ListItem>

              <ListItem>
                <ListItemText primary="• For purchases through other means: Contact our customer support team, who will evaluate your request and assist accordingly." />
              </ListItem>
            </List>
            <Typography
              style={{
                fontSize: "16px",
                margin: "8px 0",
                paddingLeft: "15px",
                fontWeight: "bold",
              }}
            >
              5. Changes to the Policy
            </Typography>
            <Typography
              style={{ fontSize: "16px", margin: "8px 0", paddingLeft: 16 }}
            >
              Reshape reserves the right to modify this Refund and Cancellation
              Policy at any time. Any changes will be reflected in the Terms of
              Use, and continued use of the app or service signifies acceptance
              of the revised terms.
            </Typography>

            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              16. Third-Party Services
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              Integration Disclaimer: If the App integrates with third-party
              services, users acknowledge and agree to abide by the terms and
              conditions of those services.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              17. Modifications to the App
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Right to Modify: The app reserves the right to modify or
              discontinue any part of the App with or without notice. The app is
              not liable for any modification, suspension, or discontinuation of
              the App.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              18. Security
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. User Credentials: Users are responsible for maintaining the
              security of their account credentials. Any unauthorized access or
              use must be reported promptly.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              19. Feedback and Suggestions
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              User Contributions: Users are encouraged to provide feedback and
              suggestions for improvement. The app reserves the right to use,
              modify, or incorporate such feedback without obligation.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              20. Indemnification
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              User Responsibility: Users agree to indemnify and hold the app,
              its owners, employees, and affiliates harmless from any claims,
              damages, or expenses arising from their use of the App, violation
              of these Terms, or infringement of third-party rights.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              21. Force Majeure
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              The app is not liable for any failure to perform obligations under
              these Terms due to events beyond its control, including natural
              disasters, acts of terrorism, or government actions.
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              22. Entire Agreement
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              a. Superseding Agreements: These Terms constitute the entire
              agreement between the user and the app, superseding any prior
              agreements, discussions, or representations
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                margin: "16px 0 8px",
              }}
            >
              23. Severability
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              If any provision of these Terms is found to be invalid or
              unenforceable, the remaining provisions will continue to be valid
              and enforceable to the fullest extent permitted by law.
            </Typography>
            <Typography style={{ fontSize: "16px", margin: "8px 0" }}>
              Contact Information: If you have any questions or concerns about
              these Terms, please contact us at support@reshapeapp.ai.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default TermsOfUse;
